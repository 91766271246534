<template>
  <div class="pa-3 d-flex flex-column align-center _bg-default">
    <v-card pa-2 class="game">
      <v-toolbar color="indigo" class="mb-3" dense dark
        ><h3>Apta</h3>
        <v-spacer></v-spacer>
        <v-btn dark @click="HintApta = true" text>Instruksi Game</v-btn>
      </v-toolbar>
      <v-col cols="12" md="12" v-if="fromRoute">
        <v-btn
          color="indigo"
          dark
          class="ml-3"
          @click="sendText"
          >Mulai Game</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" class="ml-3">
        <unity
          src="/Tikus/Build/tikus.json"
          width="900"
          height="500"
          unityLoader="/Tikus/Build/UnityLoader.js"
          ref="myInstance"
        ></unity>
      </v-col>
    </v-card>
    <!-- <HintApta v-bind:HintApta="HintApta" @false="closeDialog" /> -->
  </div>
</template>

<script>
import Unity from "vue-unity-webgl";
// import HintApta from "../../components/_base/Game/HintApta.vue";

export default {
  name: "Game",
  components: {
    Unity,
    // HintApta
  },
  data() {
    return {
      fromRoute: localStorage.getItem("from"),
      isPlayed: false,
      HintApta: true,
      poin: 200,
      id: localStorage.getItem("id")
    };
  },
  mounted() {
    localStorage.setItem("timer_tikus", 900)
  },

  methods: {
    sendText() {
      console.log(`${this.id},${this.fromRoute}`)
      this.$refs.myInstance.message(
        "QuickGameHandler",
        "StartGame",
        `${this.id},${this.fromRoute}`
      );
      this.isPlayed = true;
    },
    closeDialog() {
      this.HintApta = false;
    }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
}
.game {
  width: max-content;
}
</style>
